import {Component} from 'react';
import LDH from '../helpers/LeopardDataHelper';

class LeopardCognitoConfig extends Component {
    // -----------------------------------------------------------------------------
    // --------------------- RC6 Testing Configuration -----------------------------
    // -----------------------------------------------------------------------------
    // static AmplifyAuthAndAPIConfig = {
    //     Auth: {
    //         Cognito: {
    //             userPoolId: 'ap-southeast-2_8mAFHBLt2',
    //             userPoolClientId: '77qsohnmc4re9m7lslealaug1k',
    //             identityPoolId: 'ap-southeast-2:4f20925e-5e32-4e44-beec-b946a8ed2af3',
    //             allowGuestAccess: false,
    //             signUpVerificationMethod: 'code'
    //         },
    //         region: "ap-southeast-2"
    //     },
    //     API: {
    //         REST: {
    //             "aws-api-gateway": {
    //                 endpoint: LDH.APIEndpointAdapter(),
    //                 region: "ap-southeast-2"
    //             }
    //         }
    //     }
    // };

    // -----------------------------------------------------------------------------
    // ------------------- Demoenv1 Testing Configuration --------------------------
    // -----------------------------------------------------------------------------
    static AmplifyAuthAndAPIConfig = {
        Auth: {
            Cognito: {
                userPoolId: 'ap-southeast-2_xrtXq3Mr6',
                userPoolClientId: '56m58m9d6q7eurkdhn02hiqc1u',
                identityPoolId: 'ap-southeast-2:955ec81d-ebcf-40f5-a482-24f619f7cfd2',
                allowGuestAccess: false,
                signUpVerificationMethod: 'code'
            },
            region: "ap-southeast-2"
        },
        API: {
            REST: {
                "aws-api-gateway": {
                    endpoint: LDH.APIEndpointAdapter(),
                    region: "ap-southeast-2"
                }
            }
        }
    };
}

export default LeopardCognitoConfig;
